.EditButton {
  background-color: #7e7d82;
  color: #fff;
  border: none;
  padding: 3px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: all ease 0.5s;
  outline: none;
}

.EditButton a {
  text-decoration: none;
  color: #fff;
}

.EditButton:hover,
.EditButton:focus {
  opacity: 0.8;
}

.EditButton:first-child {
  margin-right: 5px;
}

.BlackButton {
  background-color: #1b1c20;
  color: #fff;
  border: none;
  padding: 3px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: all ease 0.5s;
  outline: none;
}

.BlackButton:disabled {
  background-color: #aaaaaa;
  color:black;
  cursor: not-allowed;
}

.BlackButton:disabled:hover,
.BlackButton:disabled:focus{
  opacity: 1
}

.BlackButton a {
  text-decoration: none;
  color: #fff;
}

.BlackButton:hover,
.BlackButton:focus {
  opacity: 0.8;
}

.BlackButton:first-child {
  margin-right: 5px;
}
