.table-dash thead tr th {
  background-color: #1B1C20;
  color: #fff;
  font-size: 1.25em;
  padding: 5px 10px;
  border-left: 2px solid #fff;
}

.table-dash thead tr th:first-child {
  border-left: 0;
}

.table-dash tbody tr td {
  padding: 5px 10px;
  border-left: 2px solid #fff;
}

.table-dash tbody tr td:first-child {
  border-left: 0;
}

.table-dash tbody tr:nth-child(even) {
  background-color: #eeeeef;
}

.HeadArrows {
  position: absolute;
  top: 0;
  right: 0;
}

.HeadArrows svg {
  display: block;
}

.HeadArrows svg:nth-child(2) {
  margin-top: -15px;
}

.HeadCell {
  cursor: pointer;

}

.contenedor-buttons {
  text-align: center;
}

.contenedor-buttons button {
  border-top: #1a2f36 solid 2px;
  border-bottom: #1a2f36 solid 2px;
  border-left: #1a2f36 solid 2px;
  border-right: none;
  background-color: white;
  height: 40px;
  color: #1a2f36;
  padding: 0 10px;
  cursor: pointer;
  transition: all ease .5s;
  margin: 0;
}

.contenedor-buttons button:focus {
  outline: 0;
}

.contenedor-buttons button:hover {
  background-color: #1a2f36;
  color: white
}

.contenedor-buttons button:last-child {
  border-right: #1a2f36 solid 2px;
  border-radius: 0 10px 10px 0;
  padding: 0 15px;
}

.contenedor-buttons button:first-child {
  border-radius: 10px 0 0 10px;
  padding: 0 15px;
}

.contenedor-buttons button.active {
  background-color: #1a2f36;
  color: white;
}

th,td {
  text-align: center !important;
}