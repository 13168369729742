header {
    width: 100%;
    height: 100px;
    border-bottom: 10px solid #E72400;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .logo {
    height: 100%;
    box-sizing: border-box;
    padding: 15px;
}

header img {
    height: 100%;
}

header .page-title {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 500px);
    text-align: center;
}

header .page-title h1 {
    text-transform: uppercase;
    font-size: 2.2em;
    margin: 0;
}

header .user-info {
    display: flex;
    margin-right: 25px;
}


header .user-info>div {
    margin-right: 10px;
}


header .user-info .user-name {
    margin: 0;
    font-size: 1.5em;
    text-transform: uppercase;
    text-align: right;
}


header .user-info .user-rol {
    margin: 0;
    font-size: 1em;
    text-align: right;
}

header .user-info svg {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    cursor: pointer;
    transition: all ease .5s;
}

header .user-info svg:hover {
    opacity: .75;
}

header .user-info img {
    width: 58px;
    height: 58px;
    border-radius: 100%;
    cursor: pointer;
    transition: all ease .5s;
    border: 2px solid #1B1C20
}

header .user-info img:hover {
    opacity: .75;
}