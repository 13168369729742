.container-data-plan {
  display: flex;
  /* align-items: start; */
  justify-content: start;
  flex-direction: column;
  height: calc(100% - 45px);
  padding: 10px 20px 20px;
}

.content-expansion-panels {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}

.content-expansion-panels__inner {
  width: 100%;
}

.content-left {
  display: inline-flex;
  flex-wrap: wrap;
  width: 33%;
}

.content-right {
  width: 65%;
}

.content-sesions {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
}

.content-sesions td {
  border-bottom: 1px solid #ddd;
  padding: 7px;
  min-width: 30vh;
  font-size: 12px;
}

.content-units {
  height: 30vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
}

.content-units td {
  border-bottom: 1px solid #ddd;
  padding: 7px;
  min-width: 20vh;
  font-size: 12px;
}

.panel-header {
  display: block;
  background: #ddd;
  padding: 5px;
  text-align: center;
}

.panel-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.drag-item {
  user-select: none;
  display: flex;
  align-items: center;
  padding: 11px;
  font-size: 12px;
}

.content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sm-icon {
  font-size: 14px !important;
}
.m-icon{
  font-size: 22px !important;
  position: absolute;
  right: 8px;
  font-weight: 800;
}

.pddb {
  margin-bottom: 2vh;
}

/* Panel contenido del programa */

.content-item-program {
  min-height: 36px;
  display: block;
  overflow: none;
  padding: 5px 5px 0 5px;
}

.item-program {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 7px;
  margin-bottom: 5px;
  font-size: 12px;
  border-radius: 4px;
}

.pdd-table {
  margin-bottom: 20px;
}

.planeacion-content{
  display: flex;
  justify-content: space-between;
  height: inherit;
}

.planeacion-content .MuiCollapse-container {
  height: calc(100% - 40px)!important;
  overflow: auto!important;
}

.modalPlaneacion {
  overflow: auto;
  height: calc(75vh - 100px);
}

.modalPlaneacionHeader {
  position: relative;
  text-align: center;
}

.modalPlaneacionHeader span {
  position: absolute;
  right: 20px;
  top: 10px
}

.modalPlaneacionHeader img {
  width: 200px;
  margin-top: 20px;
}

.modalPlaneacionHeader h4 {
  font-size: 1.75em;
  margin: 5px;
}

.modalPlaneacionBody {
  padding: 5px;
}

.modalPlaneacionBody h5 {
  text-transform: uppercase;
  color: #888;
  margin: 10px 10px;
}

.modalPlaneacionPrograma .box-content-filosofia {
  min-height: 30px;
  height: 100%;
  box-sizing: border-box;
}
a{
  cursor: pointer;
}
.contenedor-listado-para-agregar{
  border:solid;
  height: calc(55% - 30px);
  border-width: 1px;
  border-color: grey;
  margin-top: 20px;
  overflow:auto;
  margin-left: 10px !important;
}
.title-seccion{
  height: 25px;
  text-align: center !important;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 5px;
  padding-right: 16px;
  background: rgb(27, 28, 32);
  color: white;
}
.contenedor-listado-para-agregar > div > button{
  float:right;
  margin-top: 3px;
  cursor:pointer;
  background-color: white;
  border: none;
  color: black;
  font-family: 'Roboto';
  cursor: pointer;
  transition: all ease .5s;
}

.contenedor-listado-para-agregar .boton-eliminar-bibliografia{
  float:right;
  margin-top: 3px;
  cursor:pointer;
  background-color: black;
  border: none;
  color: white;
  font-family: 'Roboto';
  cursor: pointer;
  transition: all ease .5s;
}

.contenedor-listado-agregado{
  border:solid;
  margin-left: auto;
  height: calc(55% - 30px);
  border-width: 1px;
  border-color: grey;
  margin-top: 20px;
  overflow:auto;
  margin-right: 10px !important;
}
.contenedor-nueva-bibliografia{
  margin-top: 30px;
  max-height: 300px !important;
  padding:10px;
  overflow: auto;
  text-align: center;
}
.contenedor-nueva-bibliografia .MuiFormControl-root{
  width: 100%;
  max-height: 300px !important;
}
.contenedor-listado-estatico{
  margin:10px;
}
.contenedor-listado-estatico .MuiFormControl-root{
  width: 100% !important;
  margin-top: 10px;
  color:black !important
}
.contenedor-listado-estatico .Mui-disabled{
  color:black !important
}
.cantidad-temas-asignados{
  font-size: 12px !important;
  position: absolute;
  left: 47%;
}
@media (max-width: 1776px) {
  .eddit-buton-save{
    height: 36px !important;
  }
}
.biblio-especifica{
  margin-top: 0px !important;
}