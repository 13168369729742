.container-inputs {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.container-inputs .MuiFormControl-root {
  margin: 20px;
}

/* DATOS GENERALES */
.container-data {
  display: flex;
  /* align-items: start; */
  justify-content: start;
  flex-direction: column;
  height: calc(100% - 45px);
  padding: 10px 20px 20px;
  overflow: auto;
}
.input-codigo-materia{
  width: 400px !important;
}
.upper {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.content-competencias {
  padding: 0 30px;
}

.content-competencias label{
  text-transform: capitalize;
}

.container-data h3 {
  margin: 10px 0;
}

.container-data:first-child {
  margin-top: 0;
}

.box-content {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 15px;
}

.grid-content:nth-child(9),
.grid-content:nth-child(10) {
  background-color: #ddd;
}

.container-data-filosofia {
  display: block;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding: 20px 50px;
}

.box-content-filosofia {
  padding: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.box-content-filosofia_title {
  font-weight: bold;
  text-align: center;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
  background-color: #ddd;
}

.note-pd {
  font-style: italic;
  font-size: 12px;
  text-align: center;
  width: inherit;
  margin: 10px 0;
}

.table-info thead tr th {
  background-color: #ddd;
  padding: 5px;
  border-left: 1px solid rgb(180, 180, 180);
}

.table-info thead tr th:first-child {
  border-left: 0;
}

.table-info tbody tr td {
  padding: 5px;
  border-left: 1px solid rgb(180, 180, 180);
}

.plan tbody tr td {
  padding: 0;
}

.plan .plan-cell {
  width: 18.5%;
}

/* PLANEACION */

.container-program {
  margin-left: 10px;
}

.content-planeacion-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.content-planeacion-top .content-progress {
  width: 100%;
  display: flex;
  align-items: center;
}

.content-program {
  text-align: center;
  margin: 10px 0;
  overflow: auto;
  border-bottom: 1px solid #ddd;
}

.content-bottom {
  text-transform: none;
  text-align: center;
}

.container-program .btnSave {
  background-color: #1B1C20;
  color: #fff;
  font-family: 'Roboto';
  border: none;
  padding: 1px 15px;
  border-radius: 7px;
  cursor: pointer;
  transition: all ease .5s;
  outline: none;
}

.box-content.box-content-uni {
  height: 15vh;
  display: flex;
  align-items: center;
}

.container-session {
  text-align: center;
}

.header-learn {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
  background-color: #ddd;
}

.row-table-learn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-table-learn td {
  width: 100%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 5px;
}

.content-learn {
  border: 1px solid #ccc;
  font-size: 14px;
}

.table-style {
  width: 100%;
  border: 1px solid #1b1c20;
  border-radius: 5px;
  font-size: 12px;
}

.table-style thead{
  background-color: #dfdfdf;
}

.table-style tr td {
  text-align: left !important;
  vertical-align: initial; 
}

.header-btn {
  display: flex;
}

.header-btn > * {
  display: inline;
}

.header-btn > span {
  margin-top: 5px;
  margin-left: 5px;
}

.left-headers{
  width: 15vw !important;
  text-align: left !important;
  background-color: #ddd;
  border-bottom: 1px;
  border-color: #aaa !important;
}
.right-cells{
  border-top:solid !important;
  border-width: 1px !important;
  border-color: #ddd !important;

}

.contenedor-evaluaciones textarea,.contenedor-evaluaciones input{
  resize:none !important;
  width: 99%;
  border:none;
  font-size:15px;
}
.contenedor-evaluaciones input{
  text-align: center;
  width: calc(99% - 45px);
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.table-parcial{
  width: 100%;
  border-collapse: collapse !important;
}
.table-parcial .tabla-subtitles{
  width: 50% !important;
}
.table-parcial, .table-parcial tr,.table-parcial th,.table-parcial td{
  border:2px solid #ccc;
  position: relative;
}
.add-row-eval, .del-row-eval{
  float: right;
  cursor: pointer;
  border:none;
}
.add-row-eval{
  background-color: black;
  color:white
}
.del-row-eval{
  background-color: red;
  color:white;
  border:solid;
  border-width: 1px;
  border-color:black;
  margin-right: 5px;
}