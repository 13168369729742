.container-data-estrategias {
  overflow: overlay;
  height: calc(100% - 15px);
  margin: 30px;
}

.table-plan {
  text-align: center;
  border: 1px solid #1b1c20;
  font-size: 12px;
  border-radius: 5px;
  width: 99%;
}

.table-head {
  display: flex;
  border-bottom: 1px solid #1b1c20;
  text-transform: uppercase;
  background-color: #d3d3d3;
}

.table-fdiv {
  border-left: 1px solid #1b1c20;
  display: grid;
  align-items: center;
}

.table-fdiv:first-child {
  border-left: none;
  padding: 0 10px;
}

.table-sdiv {
  display: flex;
  justify-content: space-around;
  min-height: 28px;
  align-items: center;
}

.table-tdiv {
  min-height: inherit;
  display: flex;
  align-items: center;
  border-top: 1px solid #1b1c20;
  width: 100%;
  justify-content: center;
  border-right: 1px solid #1b1c20;
  padding: 0 4px;
}

.table-tdiv:last-child {
  border-right: none;
}

.table-body {
  display: flex;

}

.table-body div {
  border-left: 1px solid #1b1c20;
  text-align: left;
  font-size: 11px;
}

.table-body div p {
  padding: 5px;
  margin: 2px 0;
}

.cell-indicadores {
  width: 155px;
  border-left: none !important;
}

.cell-saberes{ 
  width: 70vh;
  display: flex;
}

/* .cell-conocimientos {
  width: 136px;
}

.cell-habilidades {
  width: 136px;
}

.cell-actitudes {
  width: 136px;
} */

.cell-practica {
  width: 148px;
}

.cell-docente {
  width: 72px;
}

.cell-independiente {
  width: 99px;
}

.cell-instrumentos {
  width: 168px;
}







.table-style {
  border: 1px solid #1b1c20;
  border-radius: 5px;
  font-size: 12px;
}

.table-style thead{
  background-color: #dfdfdf;
}

.table-style tr td {
  text-align: left !important;
  vertical-align: initial;
  
}