.login-page {
    background: rgb(2,0,36);
    background: linear-gradient(340deg, rgba(2,0,36,1) 0%, rgba(27,28,32,1) 6%, rgba(231,36,0,1) 100%);
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 10vh;
}

.login-box {
    width: 400px;
    height: 360px;
    margin: 0 auto;
    -webkit-box-shadow: 0px 0px 18px -4px rgba(0,0,0,0.47);
    text-align: center;
    padding: 40px 40px 70px 40px;
}


.button-container {
    display: flex;
    justify-content: center;
}
  
.button {
    height: 50px; 
    background-color: #0000;
    outline: none;
    border: none;
    cursor: pointer;
    padding-left: 14px;
  } 

.login-box img {
    width: 80%;
    margin-bottom: 40px;
}

.login-box label {
    display: block;
    text-align: left;
    font-size: 1.2em;
}

.login-box input {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-radius: 7px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
}

.login-box input:focus {
    border: 1px solid gray;
}

.login-box button {
    background-color: #1B1C20;
    color: #fff;
    font-family: 'Roboto';
    border: none;
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
    transition: all ease .5s;
    margin-top: 20px;
    outline: none;
    width: 50%;
}

.login-box button:hover {
    opacity: .75;
}

.login-box button:focus {
    border: 1px solid #E72400;
}

.input-error {
    border-color: #a50000;
}

.error-message {
    color: #a50000;
}

.MuiCircularProgress-colorPrimary {
    color: #a50000 !important;
}


.form-page {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(340deg, rgba(2,0,36,1) 0%, rgba(27,28,32,1) 6%, rgba(231,36,0,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .image {
    max-width: 560px;
    width: 80vw;
  }
  .from {
    max-width: 380px;
    width: 80vw;
  }
  .input-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
    margin: 30px 0;
  }
  
  .input {
    text-align: center;
    border-radius: 50px;
    border: none;
    height: 48px;
    width: 96%;
    display: block;
    font-size: 25px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .button {
    height: 50px; 
    background-color: #0000;
    outline: none;
    border: none;
    cursor: pointer;
    padding-left: 14px;
  } 
  
  .loader-container {
    padding-top: 3px;
    display: block;
  }
  
  .text {
    display: block;
  }
  
  .title {
    text-align: center;
    color: white;
    margin-top: -35px;
    font-size: 40px;
    margin-bottom: 0px;
  }
  
  .input-error {
    border-color: #a50000 !important;
  }
  
  .error-message {
    color: #a50000;
  }
  
  .MuiCircularProgress-colorPrimary {
    color: #a50000 !important;
  } 
  .input-container {
    display: none;
  }
  .input-container select {
    display: none !important;
    text-align-last: center !important;
    border-radius: 50px !important;
    border: none !important;
    height: 48px !important;
    width: 100% !important;
    font-size: 25px !important;
    background-color: white !important;
    margin-bottom: -20px;
  }
  
  .link-ayuda img {
    width: 20px;
    position: absolute;
    margin-left: -30px;
  }
  