.container-indicator {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47vh;
  height: 7vh;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.indicator .indicator-left {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
}
.indicator-left label{
  text-align: center;
}

.indicator .indicator-right {
  width: 40%;
  height: 100%;
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E72400;
  background: #fff;
}

.indicator-right .label-number{
  color:black !important;
  font-size: 25px;
  margin-left: 10px;
  margin-right: 5px;
}

.indicator .indicator-left label:nth-child(1) {
  font-size: 16px;
}
.indicator .indicator-left label:nth-child(2) {
  font-size: 28px;
  font-weight: 600;
}

.indicator .indicator-right img {
  width: 50px;
}

@media (max-width: 700px) {
  .container-indicator {
    flex-direction: column;
  }
}

@media (max-width: 1120px){
  .indicator-left label{
    font-size: 15px !important;
  }
  .indicator-right .label-number{
    color:black !important;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 5px;
  }
  .indicator-right svg{
    font-size: 20px !important;
  }

  .indicator{
    margin-left: 2px;
    margin-right: 2px;
  }
}

@media (max-width: 950px){
  .indicator-left label{
    font-size: 12px !important;
  }
}