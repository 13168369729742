.dual-tablist{
  display: flexbox;
}


.right-tabs{
  display: flex;
  float:right !important
}

.left-tabs{
  float:left
}


.tabs-list {
  display: flex;
}


.tab {
  border-radius: 20px 20px 0 0;
  padding: 5px 20px;
  cursor: pointer;
  transition: all ease 0.2s;
  background-color: #b3b3b3;
  max-height: 17px;
  overflow: hidden;
}
@media (max-width: 1446px) {
  .tab {
    padding-left:8px;
    padding-right: 8px;
  }
}
@media (max-width: 1254px) {
  .tab {
    padding-left: 4px;
    padding-right: 4px;
  }
}
@media (max-width: 1192px) {
  .tab {
    max-width: 15vw;
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
  }
}
@media (max-width: 1032px) {
  .tab {
    max-width: 13vw;
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
  }
}





.tab:hover {
  z-index: 99;
}

.tab:nth-child(even) {
  background-color: #1b1c20;
  color: #fff;
  margin-left: -10px;
}

.tab.active {
  background-color: #e72400;
  z-index: 9;
  color: white;
}

.tab-content {
  height: 100%;
  width: 100%;
  border-left: 1px solid #b3b3b3;
  border-right: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  padding: 0;
}

.tab-header {
  height: 15px;
}
