.detalle-materia-form {
  display: flex;
  margin: 0 10px;
}

.detalle-materia-form label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.detalle-materia-form input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.detalle-materia-form input::placeholder {
  font-family: "Roboto";
}

.container-horario {
  margin: 10px 0;
  padding: 15px;
}

.modal-envio {
  text-align: center;
  padding: 10px 0;
}

.modal-envio-content-title {
  font-size: 20px;
}

.modal-envio-content-text {
  font-size: 17px;
}

.modal-envio-confirmacion {
  text-align: center;
  padding: 10px 0;
}

.modal-envio-confirmacion-content-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.modal-envio-confirmacion-content-text {
  font-size: 24px;
  margin-top: 6px;
  margin-bottom: 0;
}

.modal-enviando {
  text-align: center;
  padding: 10px 0;
}

.modal-enviando-content-text {
  margin: 0;
  font-size: 25px;
}

.button-subcontainer {
  position: relative;
}

.context-menu-container {
  width: 250px;
  position: absolute;
  background-color: #fff;
  display: none;
  z-index: 10;
  border: 2px solid #9a9a9a;
}

.context-menu-button {
  width: 100%;
  border: none;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  text-wrap: nowrap;
}

.context-menu-button:hover {
  background-color: #ccc;
}

.context-menu-button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.context-menu-button > svg {
  margin-right: 10px;
}

.textArea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 4px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  resize: none;
}

input.textArea {
  padding: 4px;
  text-align: center;
  max-width: 95px;
}

.deleteButton {
  background-color: #f00;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 10px;
  padding: 0;
}
